import '../styles/slider.css'
import React, { useContext, useEffect, useState } from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { graphql, PageProps } from 'gatsby'
import { Strapi__Component_Meta_Metadata, Strapi_Global, WelcomeMogulPageQuery } from 'gatsby-graphql'
import { Box, Grid, Typography } from '@mui/material'
import { useLocalizePage } from '@/utils/hooks'
import ThemeTypeContext from '@/components/themeTypeContext'
import { IPageContext } from '@/types/pages'
import { theme } from 'src/theme/ThemeProvider'
import RichText from '@/components/elements/rich-text'
import Button from '@/components/elements/button'
import { StepLabel } from '@/components/elements/step-label'
import { css } from '@emotion/css'
import { SectionDivider } from '@/components/elements/section-divider'
import Slider from 'react-slick'
import { useIsMobile } from '@/utils/hooks'
import { useLocation } from '@reach/router'
import { navigate } from 'gatsby'
import CircularProgress from '@mui/material/CircularProgress'

const CustomPrevArrow = (props: any) => (
  <div
    className={props.className}
    onClick={props.onClick}
    style={{
      background: '#F3504F',
      height: '36px',
      width: '36px',
      borderRadius: '25px',
      border: '4px solid rgba(255, 255, 255, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      top: 'initial',
      bottom: '40px',
      boxShadow: '0px 20px 40px rgba(0, 0, 0, 0.4)',
    }}
  >
    <img className="left-arrow" src="/images/left-arrow.svg" alt="vertical line" width={8} height={14} />
  </div>
)

const CustomNextArrow = (props: any) => (
  <div
    className={props.className}
    onClick={props.onClick}
    style={{
      background: '#F3504F',
      height: '36px',
      width: '36px',
      borderRadius: '25px',
      border: '4px solid rgba(255, 255, 255, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      top: 'initial',
      bottom: '40px',
      boxShadow: '0px 20px 40px rgba(0, 0, 0, 0.4)',
    }}
  >
    <img className="right-arrow" src="/images/right-arrow.svg" alt="vertical line" width={8} height={14} />
  </div>
)

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  centerMode: false,
  prevArrow: <CustomPrevArrow />,
  nextArrow: <CustomNextArrow />,
  responsive: [
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        prevArrow: false,
        nextArrow: false,
      },
    },
  ],
}

const WelcomeMogul: React.FC<PageProps<WelcomeMogulPageQuery>> = (props) => {
  const { setTheme } = useContext(ThemeTypeContext)
  setTheme('core')
  const data = props?.data
  const pageContext = props?.pageContext as IPageContext

  const locationn = props?.location
  const global = data?.strapiGlobal as Strapi_Global

  const metaData = data?.strapiWelcomeMogul?.MetaData as Strapi__Component_Meta_Metadata
  // @ts-ignore
  const welcomePage = data?.strapiWelcomeMogul?.WelcomePage as Strapi__Component_Welcome_Mogul_Welcome_Page
  // @ts-ignore
  const WelcomeSteps = data?.strapiWelcomeMogul?.WelcomeSteps as Strapi__Component_Welcome_Mogul_Welcome_Steps
  const Media = welcomePage?.MediaSection

  useLocalizePage(pageContext, locationn)
  const isMobile = useIsMobile()
  const [loading, setLoading] = useState(true)

  const location = useLocation()

  const code = new URLSearchParams(location.search).get('code')

  const clientId = process.env.GATSBY_CLIENT_ID
  const clientSecret = process.env.GATSBY_CLIENT_SECRET_KEY
  const redirect_uri = process.env.GATSBY_REDIRECT_URL
  const whopUrl = process.env.GATSBY_WHOP_URL
  const GtagId = process.env.GATSBY_GOOGLE_TAG_CONTAINER_ID

  useEffect(() => {
    const getAuthToken = async (code, clientId, clientSecret, redirect_uri) => {
      setLoading(true)
      fetch(whopUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          code: code,
          client_id: clientId,
          client_secret: clientSecret,
          redirect_uri: redirect_uri,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.access_token) {
            setLoading(false)
          }

          if (response.error) {
            setLoading(true)
            console.log(response.error)
            navigate('/not-found')
          }
        })
        .catch((err) => {
          console.error(err)
          setLoading(true)
          navigate('/not-found')
        })
    }

    getAuthToken(code, clientId, clientSecret, redirect_uri)
  }, [])

  return (
    <>
      {!loading && <SEO metaData={metaData} global={global} />}
      {loading ? (
        <div
          style={{
            height: '100vh',
            width: '100vw',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '20px',
          }}
        >
          <CircularProgress /> Verifying request...
        </div>
      ) : (
        <Layout global={global} pageContext={{ ...pageContext, ...metaData }} theme={theme}>
          <Grid
            alignItems="center"
            justifyContent="center"
            paddingTop="16px"
            sx={{
              marginBottom: '42px',
              margin: 'auto',
              gap: '20px',
            }}
          >
            <Grid item sx={{ width: '100%' }}>
              <Typography
                sx={{
                  fontSize: { xs: '18px', md: '20px' },
                  lineHeight: { xs: '25px', md: '40px' },
                  fontWeight: 400,
                  textAlign: 'center',
                  paddingBottom: { xs: '24px', md: '0px' },
                }}
              >
                {welcomePage?.SubHeading}
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: '30px', md: '40px' },
                  fontWeight: 700,
                  textAlign: 'center',
                  letterSpacing: '-0.83px',
                  paddingBottom: { xs: '27px', md: '19px' },
                }}
              >
                {welcomePage?.Title}
              </Typography>
              <RichText
                markdown={welcomePage?.Paragraph?.data?.Paragraph}
                sx={{
                  fontSize: { xs: '16px', md: '20px' },
                  fontWeight: '400',
                  lineHeight: { xs: '30px', md: '25px' },
                  textAlign: 'center',
                  paddingX: { md: '200px', xs: 0 },
                  paddingBottom: { xs: '50px', md: '3px' },
                }}
                preserveNewlines={true}
              />
            </Grid>
          </Grid>

          <SectionDivider />

          {Media?.IsVideo && (
            <Typography
              sx={{
                fontSize: { xs: '20px', md: '30px' },
                lineHeight: '40px',
                fontWeight: 700,
                textAlign: 'center',
                letterSpacing: '-0.83px',
                marginBottom: { xs: '27px', md: '31px' },
                marginTop: { xs: '69px', md: '39px' },
              }}
            >
              {Media?.Title}
            </Typography>
          )}

          {Media?.IsVideo ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: 'transparent',
                width: '100%',
                marginLeft: 'calc((-100vw + 100%)/2)',
                marginBottom: '64px',
                margin: 'auto',
                height: { xs: '100%', md: '100%' },
              }}
              className={css`
                box-shadow: -2px 16px 26px rgba(0, 0, 0, 0.4);
                @media (max-width: 1199px) {
                  box-shadow: none;
                }
              `}
            >
              <span
                className={css`
                  position: relative;
                  width: 100%;
                  height: 648px;
                  @media (max-width: 991px) {
                    height: 400px;
                    width: 100%;
                  }
                  @media (max-width: 600px) {
                    height: 188px;
                    width: 100%;
                  }
                `}
              >
                <iframe
                  className={css`
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    width: 100%;
                    height: 100%;
                  `}
                  src={Media?.VideoUrl}
                  frameBorder="0"
                  allow="autoplay; fullscreen"
                  allowFullScreen
                  title="Video"
                ></iframe>
              </span>
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: 'transparent',
                width: '100%',
                maxWidth: '668px',
                marginLeft: 'calc((-100vw + 100%)/2)',
                marginBottom: '64px',
                margin: 'auto',
                marginTop: { xs: '50px' },
              }}
            >
              <span
                className={css`
                  position: relative;
                  width: 100%;
                  height: 500px;
                  @media (max-width: 600px) {
                    height: 300px;
                  }
                `}
              >
                <img
                  src={welcomePage?.Image?.localFile?.publicURL}
                  alt="mogul"
                  style={{ maxWidth: '618px', objectFit: 'contain', margin: 'auto' }}
                  className=""
                  height={'100%'}
                  width={'100%'}
                />
              </span>
            </Box>
          )}

          {!isMobile && <SectionDivider style={{ marginTop: '80px' }} />}

          {WelcomeSteps.length <= 4 ? (
            <Box
              sx={{
                paddingY: '55px',
                width: '100vw',
                marginLeft: 'calc((-100vw + 100%)/2)',
                paddingBottom: { xs: '0px', md: '51px' },
              }}
            >
              <Grid
                container
                sx={{
                  maxWidth: { lg: 1140, xs: 900 },
                  margin: '0 auto',
                  padding: { xs: '16px 32px', md: 0 },
                  paddingBottom: { xs: '2px', md: '16px' },
                  display: { md: 'flex' },
                  justifyContent: { md: 'center' },
                }}
              >
                <Grid
                  item
                  xs={12}
                  sx={{
                    fontSize: { xs: '20px', md: '30px' },
                    fontWeight: 700,
                    textAlign: 'center',
                    paddingBottom: '41px',
                  }}
                >
                  {welcomePage?.StepsTitle}
                </Grid>

                {WelcomeSteps.map((point, i) => (
                  <Grid
                    item
                    xs={12}
                    md={3}
                    sx={{
                      display: 'flex',
                      paddingBottom: { xs: '72px', md: '0px' },
                      paddingTop: { xs: '72px', md: 'inherit' },
                      borderLeft: { xs: 'inherit', md: i == 0 ? 'inherit' : `1px solid rgba(255, 255, 255, 30%)` },
                      borderTop: { md: 'inherit', xs: i == 0 ? 'inherit' : `1px solid rgba(255, 255, 255, 30%)` },
                    }}
                  >
                    <Grid
                      container
                      sx={{
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        justifyContent: 'flex-start',
                      }}
                    >
                      <Box sx={{ width: '100%', paddingBottom: { xs: '0px', md: '21px' } }}>
                        <StepLabel style={{ margin: 'auto' }}>
                          <Box
                            sx={{
                              height: '100%',
                              width: '100%',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <Typography sx={{ fontSize: { xs: '30px', md: '40px' }, fontWeight: 'bold' }}>
                              {(i + 1).toString().padStart(2, '0')}
                            </Typography>
                          </Box>
                        </StepLabel>
                      </Box>
                      <Box sx={{ width: '100%', paddingTop: { xs: '0px', md: 'inherit' } }}>
                        <Typography
                          sx={{
                            marginX: 'auto',
                            fontSize: '17px',
                            lineHeight: '30px',
                            width: { xs: '65%', md: '85%' },
                            textAlign: 'center',
                          }}
                        >
                          {' '}
                          <RichText markdown={point.Text.data.Text} hyperlinkColor={'#FFD600'} />
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Box>
          ) : (
            <Box
              sx={{
                paddingY: '55px',
                width: '100vw',
                marginLeft: 'calc((-100vw + 100%)/2)',
                paddingBottom: { xs: '51px', md: '80px' },
              }}
              id="welcomeSlider"
            >
              <Grid
                item
                xs={12}
                sx={{
                  fontSize: { xs: '20px', md: '30px' },
                  fontWeight: 700,
                  textAlign: 'center',
                  paddingBottom: '48px',
                }}
              >
                {welcomePage?.StepsTitle}
              </Grid>
              <Grid
                container
                sx={{
                  maxWidth: { lg: 1140, xs: 900 },
                  margin: '0 auto',
                  padding: { xs: 2, md: 0 },
                }}
              >
                <Slider {...settings}>
                  {WelcomeSteps.map((point, i) => (
                    <Grid
                      item
                      xs={12}
                      md={3}
                      sx={{
                        display: 'flex',
                        paddingBottom: { xs: '50px', md: 'inherit' },
                        minWidth: '300px',
                      }}
                      className={css`
                        border-right: 1px solid rgba(255, 255, 255, 30%);
                        @media (max-width: 767px) {
                          border-right: none;
                        }
                      `}
                    >
                      <Grid
                        container
                        sx={{
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                          justifyContent: 'flex-start',
                        }}
                      >
                        <Box sx={{ width: '100%', paddingBottom: '21px' }}>
                          <StepLabel style={{ margin: 'auto' }}>
                            <Box
                              sx={{
                                height: '100%',
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              <Typography sx={{ fontSize: 40, fontWeight: 'bold' }}>
                                {' '}
                                {(i + 1).toString().padStart(2, '0')}
                              </Typography>
                            </Box>
                          </StepLabel>
                        </Box>
                        <Box sx={{ width: '100%', paddingTop: { xs: '20px', md: 'inherit' } }}>
                          <Typography
                            sx={{
                              marginX: 'auto',
                              fontSize: '17px',
                              lineHeight: '30px',
                              width: { xs: '65%', md: '85%' },
                              textAlign: 'center',
                            }}
                          >
                            {' '}
                            <RichText markdown={point.Text.data.Text} hyperlinkColor={'#FFD600'} />
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  ))}
                </Slider>
              </Grid>
            </Box>
          )}

          {!isMobile && <SectionDivider />}

          {welcomePage?.ShowButton && (
            <Grid container alignItems="center" justifyContent="center" sx={{ gap: '20px', marginBottom: '83px' }}>
              <Grid item sx={{ paddingTop: { xs: '0px', md: '51px' }, width: '100%' }}>
                <Box sx={{ width: { xs: '100%', md: '236px' }, margin: 'auto' }}>
                  <Button
                    button={welcomePage?.DiscordButton}
                    style={{
                      backgroundColor: theme.palette.secondary.main,
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          )}
        </Layout>
      )}
    </>
  )
}

export default WelcomeMogul

export const query = graphql`
  query WelcomeMogulPage($locale: String) {
    strapiGlobal(locale: { eq: $locale }) {
      ...GlobalData
    }
    strapiWelcomeMogul(locale: { eq: $locale }) {
      MetaData {
        MetaTitle
        MetaDescription
      }
      WelcomePage {
        Title
        Paragraph {
          data {
            Paragraph
          }
        }
        Image {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
            publicURL
          }
        }
        ShowButton
        DiscordButton {
          newTab
          url
          type
          icon
          text
        }
        SubHeading
        StepsTitle
        MediaSection {
          IsVideo
          Title
          VideoUrl
        }
      }
      WelcomeSteps {
        Text {
          data {
            Text
          }
        }
      }
    }
  }
`
